/*

(c) Copyright DOGI.DOG K9 Training, LLC 2022-Present. All rights reserved.

*/


/* 

* {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
}

 */

@font-face {
    font-family: oxygen;
    src: url("../img/Oxygen-Regular.ttf");
}
@font-face {
    font-family: Oxygen;
    src: url("../img/Oxygen-Bold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: Oxygen;
    src: url("../img/Oxygen-Light.ttf");
    font-weight: light;
}

:root {
    --dogi-teal-main: rgb(0, 136, 144);
    --dogi-teal-dark: hsl(184, 92%, 20%);
    --dogi-teal-dark-opq: rgba(4, 92, 98, 0.451); 
    --dogi-gold-main: hsl(43, 74%, 52%);
    --dogi-gold-main-opq: rgba(223, 171, 40, 0.8);
    --dogi-gold-light: rgb(251, 245, 232);
    --dogi-gold-mid: rgb(240, 218, 169);
    --dogi-gold-dark: rgb(107, 81, 16);
    --dogi-gold-dark-opq: rgba(107, 81, 16, 0.7);
    --dogi-fback-success: rgb(58, 148, 41);
    --dogi-modal-bkg: rgb(47, 47, 47);
    --dogi-top-logo: url("../img/dogi_bkg_top2_w.svg");
    --dogi-footer-img: url("../img/ripped.jpg");
    --dogi-menu-background: url("../img/dogi_menu_bgk3_w.svg");
    --dogi-top-landscape: url("../img/top-desert-landscape4.png");
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    font-family: Oxygen, Arial, Helvetica, sans-serif;
    color: var(--dogi-teal-dark);
    margin: 0px;
    font-size: 1.1rem;
}

body::after {
    position:absolute; 
    width:0; 
    height:0; 
    overflow:hidden; 
    z-index:-1;
    content:var(--dogi-menu-background);
}

ul {
    list-style-type: none;
}

.wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}

.footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    font-size: 0.75em;
}

.footer > p {
    margin: 0px;
    padding: 0em 1em;
    background-color: var(--dogi-gold-dark);
    background-image: var(--dogi-footer-img);
    background-position: left;
    background-repeat: repeat-y;
    color: var(--dogi-gold-light);
}

.topcontainer { 
    background: var(--dogi-top-logo), linear-gradient(170deg, var(--dogi-teal-dark) 5%, transparent 80%), var(--dogi-top-landscape), linear-gradient(170deg, var(--dogi-teal-main) 0.5%, transparent 99%),  var(--dogi-gold-light);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    min-height: 50vh;
    justify-content: space-between;
    border: 0px;
}

.banner {
    width: 100%;
    border: 0px;
    padding: 0px;
    top: 0px;
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 0px;
}

.logo_container {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    margin-left: 1em;
}

.logo_container > img {
    max-height: 51%;
    max-width: 68%;
}

#logo {
    display: none;
}

.spacer {
    padding: 10px 20px;
}

#menu_dropdown {
    display: none;
}

ul.menu_dropdown {
    background-color: var(--dogi-gold-mid);
    background-image: var(--dogi-menu-background);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: contain;
    padding-left: 0px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
    display: flex;
    flex-flow: column wrap;
    flex: 0 0 content;
    justify-content: left;
}

ul.menu_dropdown > li {
    padding: 10px 20px;
    transition: all 0.3s ease-out;
}

ul.menu_dropdown > li > a {
    color: var(--dogi-gold-dark);
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.85em;
    margin: -0.85em;
    width: 100%;
    text-align: left;
}

ul.menu_bar {
    background-color: var(--dogi-gold-dark-opq);
    padding-left: 0px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
    display: flex;
    flex-flow: column wrap;
    flex: 0 0 content;
    justify-content: center;
    height:min-content;
    transition: none;
}

ul.menu_bar > li {
    padding: 10px 20px;
    transition: all 0.3s ease-out;
}

ul.menu_bar > li > a {
    color: var(--dogi-gold-light);
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.85em;
    margin: -0.85em;
    width: 100%;
}

.menu_button {
    display: flex;
}

.boldtitle {
    font-weight: bold;
    font-size: 1.1em;
}

.services {
    font-size: 1.1em;
}

ul.services {
    list-style: none;
}

ul.services > li::before {
    content:"\2713\0020";
}

.contentbox {
    margin: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.vert_container {
    display: flex;
    justify-content:start;
    overflow: clip;
}

.horiz_sidebar {
    min-height: 100vh;
    flex: 1 0.61 38%;
}

.horiz_sidebar > img {
    height: 100%;
    width: 100%;
    object-fit:cover;
}

.horiz_content {
    min-height: 100vh;
    min-width: 600px;
    flex: 1.61 1 62%;
    display: flex;
    flex-direction: column;
}

.yellow {
    background-color:var(--dogi-gold-light);
    color: var(--dogi-teal-dark);
    flex-flow: row wrap;
}

.darklinks > p > a {
    color: var(--dogi-teal-dark);
}

.teal {
    background-color: var(--dogi-teal-dark);
    color: var(--dogi-gold-light);
    flex-flow: row wrap-reverse;
}

.lightlinks > p > a {
    color: var(--dogi-gold-light);
}

h1 {
    font-size: 1.8em; 
    font-weight: bold;
    color: var(--dogi-gold-main);
}

h2 {
    font-size: 1.4em;
    font-weight: bold;
    color: var(--dogi-gold-main);
}

#navi {
    display: none;
    position: fixed; 
    bottom: 3em; 
    right: 3em; 
    z-index: 99; 
}

#navi > div {
    display: flex;
    flex-direction: column;
}

#navi > div > button {
    border: none; 
    outline: none; 
    cursor: pointer;
    background-color: var(--dogi-gold-dark-opq);
    color: var(--dogi-gold-light);
    padding: 0.75rem;
    font-weight: bold;
    transition: all 0.3s ease-out;
}

.navi_top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.navi_bottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.material-icons {
    vertical-align: middle;
}

ul.photobox {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 100%;
    justify-content:space-around;
    max-width: 100%;
    list-style: none;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
}

li.photobox {
    display: flex;
    flex: 1 1 auto;
}

img.photobox {
    max-width: 250px;
    max-height: 100%;
    object-fit:cover;
    margin: 10px;
}

.slideshowbox {
    margin-bottom: 5%;
    margin-top: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.slideshow_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    overflow: hidden;
}

.slideshow_slide {
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.slideshow_img_l {
    width: auto; 
    height: 100%;
    border: 5px solid var(--dogi-gold-light);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transform: rotate(-3deg) scale(0.9); 
    transition: transform 0.6s ease;
    position:relative;
    animation:animateleft 0.6s
}

@keyframes animateleft{
    from{
        left:-500px;
        opacity:0
    } to{
        left:0;opacity:1
    }
}

.slideshow_img_r {
    width: auto; 
    height: 100%;
    border: 5px solid var(--dogi-gold-light);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transform: rotate(3deg) scale(0.9); 
    transition: transform 0.6s ease;
    position:relative;
    animation:animateright 0.6s
}

@keyframes animateright{
    from{
        right:-500px;
        opacity:0
    } to{
        right:0;opacity:1
    }
}

.contactbutton, .bookingbutton {
    text-align: center;
    margin: 3em;
    box-sizing: border-box;
}


.contactbutton > a {
    color: var(--dogi-gold-light);
    text-decoration: none;
    background-color: var(--dogi-gold-main);
    padding: 15px;
    border-radius: 10px;
    font-weight: bold;
    transition: all 0.3s ease-out;
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 1em;
    margin: -1em;
}

.bookingbutton > a {
    color: var(--dogi-gold-light);
    text-decoration: none;
    background-color: var(--dogi-teal-main);
    padding: 15px;
    border-radius: 10px;
    font-weight: bold;
    transition: all 0.3s ease-out;
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 1em;
    margin: -1em;
}

#booking_missing_fields {
    display: none;
}

.smicons {
    height: 64px;
}


.formcontainer {
    width: 68%;
}

#feedbackform {
    display: flex;
    flex-flow: column;
    justify-content: left;
}

#feedbackform > input, textarea, select {
    background-color: var(--dogi-gold-light);
    border: 1px solid var(--dogi-gold-main);
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

#sender_name {
    width: 38%;
}

#feedback_resp {
    display: none;
    position: fixed; 
    top: 0px; 
    width: 100%; 
    height: auto;
    z-index: 99; 
    border: none; 
    outline: none;
    margin: 0px;
    background-color: var(--dogi-fback-success);
    color: var(--dogi-gold-light);
    text-align: center;
}


#callusnow {
    display: none;
    position: fixed; 
    top: 2em; 
    right: 2em; 
    z-index: 99; 
    border: none; 
    outline: none; 
    background-color: var(--dogi-gold-dark-opq);
    color: var(--dogi-gold-light);
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 1.3em;
    font-weight: bold;
    transition: all 0.3s ease-out;
}

#callusnow > a {
    text-decoration: none;
    color: var(--dogi-gold-light);
}

.submitbutton {
    text-align: center;
    margin: 0px;
}

.submitbutton > button {
    display: inline-block;
    background-color:var(--dogi-gold-main);
    text-decoration: none;
    color: var(--dogi-gold-light);
    padding: 15px;
    border-radius: 10px;
    border-style: none;
    font-weight: bold;
    transition: all 0.3s ease-out;
    width: 100%;
}


.modal {
    border-color: var(--dogi-teal-dark);
    color: var(--dogi-gold-light);
    border-radius: 10px;
    border-style:solid;
    font-weight: normal;
    transition: all 0.3s ease-out;
    position: relative;
    font-size: smaller;
    background-color: var(--dogi-modal-bkg);
    color: var(--dogi-gold-light);
}

.modal a.close-modal {
    background-image: url("/img/dogi_close_btn_web.png");
}

.modal_content {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
}

/* .modal_content > p > input, textarea, select { */
#desired_date, #customer_name, #customer_contact, #notes, #dog_name, #dog_breed {
    border: 1px solid var(--dogi-teal-main);
    color: var(--dogi-gold-light);
    background-color: var(--dogi-modal-bkg);
    border-radius: 10px;
}

.modalcontrols, .modalcontrols > button {
    background-color:var(--dogi-modal-bkg);
    text-decoration: none;
    color: var(--dogi-gold-main);
    font-weight: bold;
    transition: all 0.3s ease-out; 
    border: 0px;
    position: relative;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
  }
   
  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid var(--dogi-teal-main);
    border-top-color: var(--dogi-modal-bkg);
    animation: spinner .6s linear infinite;

  }

  #spinnercontainer {
    display: none;
    transition: all 0.3s ease-in; 
  }

.modal_icon {
    font-size: 4em;
}



#thankyou {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
}

.icon {
    color: var(--dogi-gold-light);
    font-size: 40px;
    vertical-align: middle;
}

.phonelink {
    text-decoration: none;
    font-weight: bold;
    font-size: 1.25rem;
    color: var(--dogi-teal-dark);
}

.thingamabob {
    display: flex;
    justify-content: center;
}

.thingamabob > img {
    display: flex;
}

#nodata {
    font-size: 0.8em;
    align-self: flex-end;
}

.aligncenter {
    align-items: center;
}

.aligncenter > p {
    text-align: center;
}

.hideafter1500 {
    display: none;
}

.red {
    color: red;
}

@media screen and (max-width: 1500px) {
    .hideat1500 {
        display: none;
    }
}

@media screen and (max-width: 1300px) {
    .hideat1300 {
        display: none;
    }

    body {
        font-size: 1.0rem;
    }
}

@media screen and (max-width: 990px) {
    .hideat990 {
        display: none;
    }

    .contentbox {
        margin: 35px;
    }

    .menu_dropdown > li {
        padding: 10px 35px;
    }

    .horiz_content {
        max-width: 100%;
        min-width: 100%;
    }

    .logo_container > img {
        margin-left: 2em;
        max-height: 51%;
    }

    .horiz_sidebar {
        max-height: 150vh;
    }

    body {
        font-size: 0.8rem;
    }

    .icon {
        font-size: 24px;
    }

    #navi {
        bottom: 1.75em;
        right: 1.75em;
    }

    #callusnow {
        top: 1.75em;
        right: 1.75em;
    }
}

@media screen and (orientation: portrait) {

    .hideinportrait {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .hideat800 {
        display: none;
    }

    .formcontainer {
        width: 100%;
    }

    #submit {
        width: unset;
    }

}

@media screen and (max-width: 500px) {
    .hideat500 {
        display: none;
    }

    .smicons {
        height: 42px;
    }

    .logo_container > img {
        margin-left: unset;
        max-height: unset;
    }

    .icon {
        font-size: 20px;
    }
}

@media (any-hover: hover) {
    #navi > div > button:hover {
        background-color: var(--dogi-gold-main-opq);
    }

    .submitbutton > button:hover {
        background-color: var(--dogi-gold-dark);
        cursor:pointer;
    }

    .contactbutton > a:hover {
        background-color: var(--dogi-gold-dark);
    }

    .bookingbutton > a:hover {
        background-color: var(--dogi-teal-dark);
    }
    
    ul.menu_bar > li:hover {
        background-color: var(--dogi-gold-main-opq);
    }

    ul.menu_dropdown > li:hover {
        background-color: var(--dogi-gold-main-opq);
        padding-left: 2em;
    }

    .modalcontrols > button:hover {
        color: var(--dogi-gold-light);
    }
}


